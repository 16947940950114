<template>
  <!-- 北美SDE求职旗舰班试听 -->
  <div class="vip-page">
    <div sticky-container>
      <div class="page-wrap">
        <div
          class="page-banner"
          :style="{ backgroundImage: 'url(' + img + ')' }"
        >
          <div class="container">
            <div>
              <pageSelect
                :br="br"
                :bantxt="bantxt"
                @changebr="(val) => (br = val)"
              ></pageSelect>
            </div>
            <div class="sch-title">北美SDE项⽬速成班</div>
            <div class="sch-desc">
				专为北美CS求职量身定制的项⽬速成班 硬核⼯业级项⽬<br />
              2-3周速成5-7个项⽬，填满简历就去⾯试
            </div>
            <ul class="leet-date leet-sm-space">
<!--              <li>-->
<!--                <div class="val">7 <span>人</span></div>-->
<!--                <div class="txt">VIP小班</div>-->
<!--              </li>-->
              <li>
                <div class="val">1v1 <span>交付</span></div>
                <div class="txt">全程指导答疑</div>
              </li>
              <li>
                <div class="val">30 <span>+</span></div>
                <div class="txt">技术栈⽀撑</div>
              </li>
              <li>
                <div class="val">2-3<span>周</span></div>
                <div class="txt">速成简历</div>
              </li>
              <li>
                <div class="val">5-10</div>
                <div class="txt">项⽬数量</div>
              </li>
              <li>
                <div class="val">0 <span>元购</span></div>
                <div class="txt">旗舰班已包含</div>
              </li>
            </ul>
            <!-- 视频 -->
            <div @click="videoShow = true" class="page-video">
<!--              <img src="../../assets/images/scb/shipin.png" alt="" />-->
              <img src="../../assets/images/11-SDEFast/shipin-Edward.png" alt="" />
              <div class="v-icon"></div>
<!--              <div class="v-title">课程简介 & 免费试听</div>-->
            </div>
            <!--            <div class="countDownText" v-if="countDownTimeStamp != -1">-->
            <!--              <span class="text"-->
            <!--                >下一期开课：<span class="countsNum">{{ countDays }}</span-->
            <!--                >天<span class="countsNum">{{ countHours }}</span-->
            <!--                >小时<span class="countsNum">{{ countMinutes }}</span-->
            <!--                >分<span class="countsNum">{{ countSeconds }}</span-->
            <!--                >秒</span-->
            <!--              >-->
            <!--            </div>-->
          </div>
        </div>
        <poly-video
          v-if="videoShow"
          :br="br"
          @closeVideo="videoShow = false"
        ></poly-video>
<!--        <div class="priceBanner">-->
<!--          <div class="priceBannerArea">-->
<!--            <div class="priceBannerContent">-->
<!--              <img src="@/assets/imgs/priceBanner.png" alt="" />-->
<!--              <div class="priceBannerInner">-->
<!--                <div class="priceLeft">-->
<!--                  <div class="prices" v-if="comboData.length > 0">-->
<!--                    <span style="font-size: 28px; color: #de1306">-->
<!--&lt;!&ndash;                      ${{&ndash;&gt;-->
<!--&lt;!&ndash;                        comboData[0].usddiscountPrice != 0&ndash;&gt;-->
<!--&lt;!&ndash;                          ? comboData[0].usddiscountPrice&ndash;&gt;-->
<!--&lt;!&ndash;                          : comboData[0].usdprice&ndash;&gt;-->
<!--&lt;!&ndash;                      }}&ndash;&gt;-->
<!--                      ${{comboData[0].usdprice}}-->
<!--                    </span-->
<!--                    >-->
<!--                    <span style="font-size: 16px; color: #e02020"-->
<!--                      >&nbsp;-->
<!--&lt;!&ndash;                      /&nbsp;&yen;{{&ndash;&gt;-->
<!--&lt;!&ndash;                        comboData[0].discountPrice != 0&ndash;&gt;-->
<!--&lt;!&ndash;                          ? comboData[0].discountPrice&ndash;&gt;-->
<!--&lt;!&ndash;                          : comboData[0].price&ndash;&gt;-->
<!--&lt;!&ndash;                      }}&ndash;&gt;-->
<!--&lt;!&ndash;                      / &yen;50000&ndash;&gt;-->
<!--                      / &yen;{{comboData[0].price}}-->
<!--                    </span-->
<!--                    >-->
<!--                  </div>-->
<!--                  <div class="pricesTips">-->
<!--                    <div class="dollar">支持美元付款</div>-->
<!--                    <div class="huabei">支持花呗分期</div>-->
<!--                  </div>-->
<!--                </div>-->

<!--                <div class="priceRight">-->
<!--                  <el-button class="classHelp" @click="helpVisible = true"-->
<!--                    >课程咨询</el-button-->
<!--                  >-->
<!--                  <el-button class="buyNow" @click="jumptoPrice"-->
<!--                    >立即购买</el-button-->
<!--                  >-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
        <helpClass
          :visible="helpVisible"
          @setHelpVisible="(val) => (helpVisible = val)"
        ></helpClass>

        <div
          class="sticky"
          v-sticky="stickyEnabled"
          sticky-offset="{top: 0, bottom: 30}"
          sticky-side="both"
          on-stick="onStick"
          sticky-z-index="99920"
        >
          <div class="page-navbar">
            <div class="container">
              <pageStickySelect
                :dr="dr"
                @changedr="(val) => (dr = val)"
              ></pageStickySelect>
              <Menus
                :menus="menuList"
                @TabActiveIndexChange="TabActiveIndexChange"
                :current-index="currentIndex"
              />
              <div v-if="stickyToggle" class="price" style="right: 75px">
<!--                <span-->
<!--                  style="font-size: 16px; color: #de1306"-->
<!--                  v-if="comboData.length > 0"-->
<!--                >-->
<!--                  ${{comboData[0].usdprice }}-->
<!--                </span>-->
<!--                <span style="font-size: 12px; color: #e02020"-->
<!--                  >-->
<!--&lt;!&ndash;                  /&yen;50000&ndash;&gt;-->
<!--                  /&yen;{{comboData[0].price }}-->
<!--                </span-->
<!--                >-->

<!--                <div class="priceDetail" @click="jumptoPrice">价格详情</div>-->
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="
            TabActiveIndex === 'course_intro' ||
            TabActiveIndex === 'course_price'
          "
        >
		<div class="g-section">
			<div class="g-header">
				<h3>针对学⽣量身定制的项⽬课，满⾜学⽣着急的需求</h3>
				<p>简历空空，没有竞争优势，急需提升背景信息，不知如何下⼿的北美CS</p>
			</div>
			<div class="container">
				<div class="need-cells">
					<div class="col">
						<div class="need-card hoverUp">
							<div class="need-icon"><img src="../../assets/images/11-SDEFast/jianli.png" alt="" /></div>
							<div class="flex-col">
								<h3>简历空空如也</h3>
								<p>简历空空，除了学校没有其他CS项⽬经历，尤其转码同学。</p>
							</div>
						</div>
					</div>
					<div class="col">
						<div class="need-card hoverUp">
							<div class="need-icon"><img src="../../assets/images/11-SDEFast/shuchu.png" alt="" /></div>
							<div class="flex-col">
								<h3>简历太⽔，拿不出⼿</h3>
								<p>简历背景太弱太⽔，都是学校的⼩项⽬拿不出⼿。</p>
							</div>
						</div>
					</div>
					<div class="col">
						<div class="need-card hoverUp">
							<div class="need-icon"><img src="../../assets/images/11-SDEFast/time.png" alt="" /></div>
							<div class="flex-col">
								<h3>临近求职⾯试但来不及</h3>
								<p>身边同学都在投，⾃⼰也着急，⽆从下⼿，⼼急如焚。</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="g-middle">
			<div class="g-section">
				<div class="g-header">
					<h3>⼯业级项⽬+多技术讲解，五⼤类型平台逻辑解析</h3>
					<p>绝对硬核技术的项⽬课，轻松对应简历关</p>
				</div>
				<div class="container">
					<div class="proj-main u-flex">
						<div class="proj-photo">
							<img src="../../assets/images/11-SDEFast/pc-code.png" alt="" />
						</div>
						<div class="flex-col">
							<div class="proj-card">
								<ul class="proj-list">
									<li>
										<h3>01.核⼼技术</h3>
										<p>Redis缓存、ElasticSearch搜索引擎、WebSocket即时通信、AOP⽇志、Git版本控制...</p>
									</li>
									<li>
										<h3>02.分布式介绍</h3>
										<p>Eureka服务注册、Feign组件调⽤、Hystrix断路器、Zuul微服务⽹管...</p>
									</li>
									<li>
										<h3>03.前端技术</h3>
										<p>HTML、CSS、JavaScript、Bootstrap、JQuery、Ajax、freemarke...</p>
									</li>
									<li>
										<h3>04.后端框架</h3>
										<p>Spring Boot、Spring MVC、Maven、Tomcat、Jetty、Linux..</p>
									</li>
									<li>
										<h3>05.数据库相关</h3>
										<p>MySql、MyBatis、SQL、MongoDB...</p>
									</li>
									<li>
										<h3>06.五⼤类型平台</h3>
										<p>ToC平台、ToB平台、社交平台、视频平台、⾃制平台</p>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="g-section">
				<div class="g-header">
					<h3>符合⾯试需要，短期速成的硬核项⽬课</h3>
					<p>⼿把⼿循序渐进带你完成项⽬，简历实现质的⻜跃</p>
				</div>
				<div class="container">
					<div class="core-cells u-flex">
						<div class="col">
							<div class="core-item">
								<div class="core-icon"><img src="../../assets/images/11-SDEFast/core-1.png" alt="" /></div>
								<h3>2-3周速成</h3>
								<p>北美招聘在即，正常⼀个项⽬1个⽉，四个项⽬4个⽉，招聘已经结束，需要最快时间完善简历去⾯试</p>
							</div>
						</div>
						<div class="col">
							<div class="core-item">
								<div class="core-icon"><img src="../../assets/images/11-SDEFast/core-2.png" alt="" /></div>
								<h3>零基础友好</h3>
								<p>转码零基础，只需数据结构即可轻松上⼿，没有障碍，省去⼤量计算机基础课程铺垫</p>
							</div>
						</div>
						<div class="col">
							<div class="core-item">
								<div class="core-icon"><img src="../../assets/images/11-SDEFast/core-3.png" alt="" /></div>
								<h3>硬核项⽬课</h3>
								<p>⼯业级框架，30+技术栈充实项⽬，不再是学校的⽔项⽬，让简历不再⼀⽚空⽩</p>
							</div>
						</div>
						<div class="col">
							<div class="core-item">
								<div class="core-icon"><img src="../../assets/images/11-SDEFast/core-4.png" alt="" /></div>
								<h3>简历内容写好</h3>
								<p>做完项⽬，如何书写简历，指导⼀应俱全，保姆级教程教你怎么把项⽬写进简历⾥</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="g-section" style="background-color: #F4F9FF;">
			<div class="g-header">
				<h3>北美CS⾸选的SDE项⽬速成班，再也不为简历空⽩发愁</h3>
				<p>硬核⼯业级项⽬，⾮同学校⽔项⽬，丰富简历项⽬背景，提升投递竞争⼒</p>
			</div>
			<div class="container">
				<div class="step-mains">
					<div class="step-first">
						<div class="step-card">
							<h3>完整项⽬搭建</h3>
							<p>建⽴⼀个完整的前后端技术⽹站， 有理论有实操</p>
						</div>
						<div class="step-card">
							<h3>项⽬框架设计</h3>
							<p>五⼤类型平台，各种类型平台具 体应⽤，理解逻辑结构，设计属于 ⾃⼰的 app</p>
						</div>
					</div>
					<div class="step-line">
						<img src="../../assets/images/11-SDEFast/step.png" alt="" />
					</div>
					<div class="step-second">
						<div class="step-card">
							<h3>先修必备基础知识</h3>
							<p>夯实基础，稳扎稳打，为迅速 上⼿项⽬打下基⽯</p>
						</div>
						<div class="step-card">
							<h3>热⻔技术栈</h3>
							<p>掌握⼯业级常⻅核⼼要点， 把握各种技术细节，丰富简历</p>
						</div>
						<div class="step-card">
							<h3>简历书写</h3>
							<p>简历项⽬书写，⾯试对答如流</p>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="g-section">
			<div class="g-header">
				<h3>0元购项⽬课，同时解决刷题+项⽬+简历所有问题，⽴省$7000+</h3>
				<p>其他机构刷题课程，和项⽬课程分开，收两份钱，我们都在⼀起</p>
			</div>
			<div class="container">
				<div class="zero-lesson u-flex">
					<div class="col">
						<div class="zero-card zero-other-card">
							<div class="zero-info">
								<div class="zero-item">
									<div class="zero-photo"><img src="../../assets/images/11-SDEFast/z-pic-1.png" alt="" /></div>
									<div class="zero-title">
										刷题⾯试 <span class="text-primary">$7000+</span>
									</div>
								</div>
								<div class="zero-plus">
									<img src="../../assets/images/leet/plus.png" alt="" />
								</div>
								<div class="zero-item">
									<div class="zero-photo"><img src="../../assets/images/11-SDEFast/z-pic-2.png" alt="" /></div>
									<div class="zero-title">
										简历项⽬ <span class="text-primary">$7000+</span>
									</div>
								</div>
							</div>
							<div class="zero-foot">
								<div class="zero-gray-tag">其他机构：$15000+</div>
							</div>
						</div>
					</div>
					<div class="col">
						<div class="zero-card zero-cson-card">
							<div class="zero-info">
								<div class="zero-item">
									<div class="zero-photo"><img src="../../assets/images/11-SDEFast/z-pic-3.png" alt="" /></div>
									<div class="zero-title">
										刷题⾯试+简历项⽬ <br>
										<span class="text-primary">《北美SDE求职旗舰班》</span>
									</div>
								</div>
							</div>
							<div class="zero-foot">
								<div class="zero-blue-tag">
									CSON全部包括
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<addBanner title="北美CS留学求职全方位讲解梳理，打通各个卡点" desc="帮你用最短的时间获取最有效的信息，北美CS留学求职一网打尽" :imgs="img2" btnClass="btn1"></addBanner>
          <div class="g-section leet-jj-section" id="discountPrice">
            <a name="tt-price"> </a>
            <div class="g-header">
              <h3>课程咨询</h3>
            </div>
            <div>
              <div class="container">
                <div class="gg-yh-panel sc-yh-panel clearfix">
                  <div
                      class="gg-card"
                      style="margin: 0 auto !important"
                      v-if="comboData && comboData.length > 0"
                  >
                    <el-row>
                      <el-col :span="comboData.length > 1 ? 12 : 24">
                        <div class="gg-yh-cell">
<!--                          <div-->
<!--                              :class="[-->
<!--                              comboData.length > 1-->
<!--                                ? 'gg-yh-grid red'-->
<!--                                : 'gg-yh-grid',-->
<!--                            ]"-->
<!--                          >-->
<!--                            <div class="gg-price">-->
<!--                              ${{comboData[0].usdprice}}-->
<!--                            </div>-->
<!--                            <div class="gg-y-price">-->
<!--                              ¥{{comboData[0].price}}-->
<!--                            </div>-->
<!--                          </div>-->
                          <div class="gg-yh-grid">
                            <div class="gg-weixin">
                              <img src="../../assets/images/lxZixun/客服微信.jpeg" alt="" />
                            </div>

                          </div>
                          <div class="gg-yh-body">
                            <div class="tip">
                              此课程以包含在《北美SDE求职旗舰班》 <br />⽆需额外购买，不单独售卖
                            </div>
                            <ul class="gg-yh-item">
                              <li>
                                <i class="iconfont icon-dui"></i
                                >2-3周速成5-7个项⽬ 
                              </li>
                              <li>
                                <i class="iconfont icon-dui"></i
                                >30+常⽤技术栈，丰富简历背景
                              </li>
                              <li>
                                <i class="iconfont icon-dui"></i
                                >解决简历⽆项⽬、⽔项⽬难题 
                              </li>
                              <li>
                                <i class="iconfont icon-dui"></i
                                >速成项⽬简历，⻢上去⾯试
                              </li>
                            </ul>
                            <div class="yh-foot">
<!--                              <el-button-->
<!--                                  type="buy"-->
<!--                                  @click="buyCombo"-->
<!--                                  :class="[comboData.length > 1 ? 'red' : '']"-->
<!--                              >立即购买-->
<!--                              </el-button>-->
                            </div>
                          </div>
                        </div>
                      </el-col>
                    </el-row>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="TabActiveIndex == 'course_question'" class="questionArea">
          <presales-consultation :objectId="7"></presales-consultation>
        </div>

<!--        <courseMeal-->
<!--          v-if="TabActiveIndex == 'course_content'"-->
<!--          id="7"-->
<!--        ></courseMeal>-->
        <!-- <course-detail :TabActive="TabActiveIndex" :course-i-d="courseId" /> -->
<!--		  <course-detail :TabActive="TabActiveIndex" :course-i-d="courseId" /> -->
      </div>
    </div>
    <leetDialog></leetDialog>
  </div>
</template>

<script>
import leetDialog from "@/components/leetDialog.vue";
import courseMix from "../../utils/courseMix";
import courseDetail from "../../components/course/courseDetail";
import Menus from "../../components/course/menu";
import offer from "./components/offer";
import freeSystem from "./components/freeSystem";
import presalesConsultation from "./components/presalesConsultation";
import polyVideo from "./components/polyVideo";
import courseDescription from "./components/courseDescription";
import courseMeal from "./components/courseMeal";
import pageSelect from "./components/pageSelect";
import pageStickySelect from "./components/pageStickySelect";
import helpClass from "./components/help.vue";
import { getCountDownClass } from "@/service/index";
import UML from "@/components/course/coursesComponents/UML";
import OOD from "@/components/course/coursesComponents/OOD";
import ObjectOriented from "@/components/course/coursesComponents/ObjectOriented";
import UMLDetail from "@/components/course/coursesComponents/UMLDetail";
import { getCourseBanner } from "@/service";
import addBanner from "./components/addBanner";

export default {
  components: {
    leetDialog,
    Menus,
    courseDetail,
    offer,
    freeSystem,
    polyVideo,
    courseDescription,
    presalesConsultation,
    courseMeal,
    pageSelect,
    pageStickySelect,
    helpClass,
    UML,
    OOD,
    ObjectOriented,
    UMLDetail,
	addBanner
  },
  mixins: [courseMix],
  data() {
    return {
      stickyToggle: false,
      stickyEnabled: true,
      videoShow: false,
      bantxt: "北美求职-SDE项⽬速成班",
      dr: 10,
      br: 10,
      txShow: true,
      curId: 0,
      tradio: 3,
      bzid: 0,
      Pselect: "1",
      img: require("../../assets/images/11-SDEFast/banner.jpg"),
	  img2: require("../../assets/images/beimei/ad-banner-1.jpg"),
      form: {
        region: "v1",
      },
      activeNames: ["1"],
      TabActiveIndex: "course_intro",
      menuList: [
        { name: "班课介绍", index: "0", tag: "course_intro" },
        // { name: "班课套餐", index: "1", tag: "course_meal" },
        // { name: "班课大纲", index: "1", tag: "course_content" },
        // { name: "班课模式", index: "2", tag: "course_mode" },
        // {name: "用户评价", index: "1", tag: "course_comment"},
        // { name: "售前咨询", index: "2", tag: "course_question" },
      ],
      currentIndex: "0",
      recommendClasses: [], //相关课程推荐byzuhuimin
      helpVisible: false,
      countDownText: "",
      countDownTimeStamp: -1,
      countDays: 0,
      countHours: 0,
      countMinutes: 0,
      countSeconds: 0,
      qrcode: {},
    };
  },
  created() {
    // this.setEndTime(10);
    console.log(this.startTime);
    this.getCountDownClass();
  },
  mounted() {
    window.scrollTo(0, 0);
    getCourseBanner(2).then((res) => {
      if (res.success) {
        this.qrcode = res.result[0];
      }
    });
  },
  methods: {
    handleCommand(command) {
      // this.$message('click on item ' + command);
      this.bantxt = command;
      console.log(command);
      switch (command) {
        case "10天LeetCode经典100题":
          this.$router.push("/course/1");
          break;
        case "求职面试算法班":
          this.$router.push("/course/4");
          break;
        case "求职面试刷题班":
          this.$router.push("/course/5");
          break;
        case "北美SDE算法速成班":
          this.$router.push("/course/3");
          break;
        case "LeetCode千题视频讲解":
          this.$router.push("/course/2");
          break;
        case "北美SDE求职速成班":
          this.$router.push("/course/6");
          break;
        case "北美SDE求职旗舰班":
          this.$router.push("/course/7");
          break;
      }
    },
    changePage(index) {
      this.$router.push(`/course/${index}`);
    },
    onStick(data) {
      console.log(data);
      this.stickyToggle = data.sticked;
    },
    enter: function (e) {
      console.log(e);
      this.dr = e;
    },
    brenter: function (e) {
      console.log(e);
      this.br = e;
    },
    echange(val) {
      this.bzid = val;
    },
    setActiveItem(index) {
      this.$refs.carousel.setActiveItem(index);
    },
    jumptoPrice() {
      this.TabActiveIndex = "course_intro";
      this.currentIndex = "0";
      this.$nextTick(() => {
        document.documentElement.scrollTop =
          this.$el.querySelector("#discountPrice").offsetTop - 60;
      });
    },
    timeCountDown(endDateStr) {
      if (endDateStr == -1) {
        return;
      }
      //结束时间
      var endDate = new Date(endDateStr);
      //当前时间
      var nowDate = new Date();
      //相差的总秒数
      if (endDate < nowDate) {
        window.location.reload();
      }
      var totalSeconds = parseInt((endDate - nowDate) / 1000);
      //天数
      var days = Math.floor(totalSeconds / (60 * 60 * 24));

      //取模（余数）
      var modulo = totalSeconds % (60 * 60 * 24);

      //小时数
      var hours = Math.floor(modulo / (60 * 60));

      modulo = modulo % (60 * 60);
      //分钟
      var minutes = Math.floor(modulo / 60);
      //秒

      var seconds = modulo % 60;

      this.countDays = days;
      this.countHours = hours;
      this.countMinutes = minutes;
      this.countSeconds = seconds >= 10 ? seconds : `0${seconds}`;

      setTimeout(() => {
        this.timeCountDown(endDateStr);
      }, 1000);
    },
    getCountDownClass() {
      getCountDownClass(7).then((res) => {
        if (res.success) {
          this.countDownTimeStamp = res.result;
          this.timeCountDown(this.countDownTimeStamp);
        }
      });
    },
  },
};
</script>

<style scoped="scoped" lang="scss">
@import url("../../assets/css/courses/common.css");
@import url("../../assets/css/courses/11-SDEFast.css");
.gg-weixin{
  img{
    width: 124px;
    height: 124px;
  }
}
</style>
